import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import { refreshLogin } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import getDateString from '../utils/getDateString';
import getSymbolFromCurrency from 'currency-symbol-map';

const OrderListPage = ({ history, match }) => {
	const pageNumber = match.params.pageNumber || 1; // to fetch various pages of orders
	const dispatch = useDispatch();
	
	// const orderListAll = useSelector((state) => state.orderListAll); // to avoid blank screen display
	// const { loading, orders, error, page, pages, total } = orderListAll;
	
	const orderListUser = useSelector((state) => state.orderListUser); // to avoid blank screen display
	const { loading, orders, error } = orderListUser;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDetails = useSelector((state) => state.userDetails);
	const { error: userLoginError } = userDetails;

	// refresh access tokens aif user details are failed
	useEffect(() => {
		if (userLoginError && userInfo && !userInfo.isSocialLogin) {
			const user = JSON.parse(localStorage.getItem('userInfo'));
			user && dispatch(refreshLogin(user.email));
		}
	}, [userLoginError, dispatch, userInfo]);

	// get all orders by pagenumber
	useEffect(() => {
		if (userInfo) dispatch(listMyOrders(pageNumber));
		else history.push('/login');
	}, [dispatch, history, userInfo, pageNumber]);

	return (
		<>
	
			<Row className='align-items-center'>
				<Col>
					<h1 style={{
          border: "0.5px solid #9ab49a",
          marginBottom: "16px",
          borderRadius: "16px",
          paddingLeft: "12px",
		  backgroundColor: '#e3ffe3',
		  color: 'grey'
        }}>All Orders</h1>
				</Col>
			</Row>
			{loading ? (
				<Loader />
			) : error ? (
				<Message dismissible variant='danger' duration={10}>
					{error}
				</Message>
			) : (
				<Table
					striped
					bordered
					responsive
					className='table-sm text-center'>
					<thead>
						<tr>
								
							<th style={{backgroundColor: '#154a15', color: 'white', borderTopLeftRadius: '16px', borderRight: '1px solid white'}}>ID</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderRight: '1px solid white'}}>CUSTOMER</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderRight: '1px solid white'}}>TOTAL</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderRight: '1px solid white'}}>DATE</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderRight: '1px solid white'}}>PAID</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderRight: '1px solid white'}}>DELIVERED</th>
							<th style={{backgroundColor: '#154a15', color: 'white', borderTopRightRadius: '16px', border: 'none'}}>ACTION</th>
						</tr>
					</thead>
					<tbody>
						{orders.orders &&
							orders.orders.map((order) => {
								return (
									<tr key={order._id}>
										<td>{order._id}</td>
										<td>{order.user && order.user.name}</td>
										<td>
											{getSymbolFromCurrency('NGN')}
											{order.totalPrice}
										</td>
										<td>
											{getDateString(order.createdAt)}
										</td>
										<td>
											{order.isPaid ? (
												getDateString(order.paidAt)
											) : (
												<i
													className='fas fa-times'
													style={{
														color: 'red',
													}}
												/>
											)}
										</td>
										<td>
											{order.isDelivered ? (
												getDateString(order.deliveredAt)
											) : (
												<i
													className='fas fa-times'
													style={{
														color: 'red',
													}}
												/>
											)}
										</td>
										<td
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-around',
												textDecoration: 'none',
											}}>
											<LinkContainer
												to={`/order/${order._id}`}>
												<Button
													variant='link'
													className='btn-sm'>
													View Details
												</Button>
											</LinkContainer>
										</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			)}
			<Paginate
				// pages={orders.pages}
				// page={orders.page}
			
			/>
		</>
	);
};

export default OrderListPage;
