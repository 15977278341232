import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, FloatingLabel, Button, } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import { createProduct } from "../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
import { refreshLogin, getUserDetails } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
//import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";

const ProductCreate = ({ history, match }) => {
  //==========================================================================
  // all variable for stroing product details
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  //const [image, setImage] = useState("");
  const [price, setPrice] = useState(0.0);
  const [countInStock, setCountInStock] = useState(0);

  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  // to upload product image
  // const [uploading, setUploading] = useState(false);
  // const [errorImageUpload, setErrorImageUpload] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error: userLoginError } = userDetails;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    //success: successCreate,
    error: errorCreate,
    //product: createdProduct,
  } = productCreate;

  //==========================================================================

  // fetch user login info
  useEffect(() => {
    userInfo && dispatch(getUserDetails("profile"));
    //: dispatch(getUserDetails('profile'));
  }, [userInfo, dispatch]);
  // // fetch user login info
  // useEffect(() => {
  // 	userInfo
  // 		? userInfo.isSocialLogin
  // 			? dispatch(getUserDetails(userInfo.id))
  // 			: dispatch(getUserDetails('profile'))
  // 		: dispatch(getUserDetails('profile'));
  // }, [userInfo, dispatch]);

  // refresh token for expired access tokens
  useEffect(() => {
    if (userLoginError && userInfo) {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      user && dispatch(refreshLogin(user.email));
    }
  }, [userLoginError, dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo) history.push("/login");
    dispatch({ type: PRODUCT_CREATE_RESET }); //reset the new product detail
  }, [dispatch, history, userInfo]);

  //=========================================================================

  // submit the product details
  // const handleSubmit2 = (e) => {
  //   e.preventDefault();
  //   dispatch(
  //     createProduct({
  //       name,
  //       brand,
  //       price,
  //       category,
  //       description,
  //       countInStock,
  //       image,
  //     })
  //   );
  // };

  // // for image input, use a ref
  // const inputFile = useRef(null);

  // // click the above ref, to handle the overlay div above the product image
  // const handleImageClick = () => {
  //   inputFile.current.click();
  // };

  // // submit file to aws bucket, get the url
  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   setUploading(true);
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };

  //     const { data } = await axios.post("/api/upload", formData, config);
  //     setImage(data);
  //     setUploading(false);
  //   } catch (error) {
  //     setErrorImageUpload("Please choose a valid image");
  //     setUploading(false);
  //   }
  // };
  //==========================================================================

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldImages) => [...oldImages, reader.result]);
          setImages((oldImages) => [...oldImages, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  //==========================================================================

  const handleSubmit = (e) => {
    e.preventDefault();

    // required field checks

    if (images.length <= 0) {
      alert("Please upload an image");
      return;
    }

    const formData = new FormData();

    formData.set("name", name);
    formData.set("price", price);
    formData.set("brand", brand);
    formData.set("category", category);
    formData.set("countInStock", countInStock);
    formData.set("description", description);

    images.forEach((image) => {
      formData.append("images", image);
    });

    dispatch(createProduct(formData));
  };
  //==========================================================================

  return (
    <>
      <Meta title="Agrolinka | New product" />

      <Link to="/products">
        <Button variant="outline-primary" className="mt-3 go-back">
          Go Back
        </Button>
      </Link>
      <FormContainer style={{ marginTop: "-2em" }}>
        <h1 style={{
          border: "0.5px solid #9ab49a",
          marginBottom: "16px",
          borderRadius: "16px",
          paddingLeft: "12px",
		  backgroundColor: '#e3ffe3',
		  color: 'grey'
        }}>Create Product</h1>
        {loadingCreate ? (
          <Loader />
        ) : errorCreate ? (
          <Message dismissible variant="danger" duration={10}>
            {errorCreate}
          </Message>
        ) : (
          <>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Form.Group controlId="name">
                <FloatingLabel
                  controlId="nameinput"
                  label="Product name"
                  className="mb-3"
                >
                  <Form.Control
                    size="lg"
                    placeholder="Enter Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="price">
                <FloatingLabel
                  controlId="priceinput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    size="lg"
                    placeholder="Enter price"
                    type="number"
                    value={price}
                    min="0"
                    step="0.1"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              {/* ================================================ */}

              <h6> Product Image</h6>
              <div style={{ width: "120px" }}>
                {imagesPreview.map((image, i) => (
                  <img
                    draggable="false"
                    src={image}
                    style={{
                      width: "120px",
                      borderRadius: "8px",
                      marginBottom: "16px",
                    }}
                    alt="Product"
                    key={i}
                  />
                ))}
              </div>
              <Button style={{ borderRadius: "8px", 
                    marginBottom: "16px", 
                    backgroundColor: 'green',
                    border: 'none' }}>
                <input
                  type="file"
                  name="images"
                  accept="image/*"
                  multiple
                  onChange={handleProductImageChange}
                  className="hidden"
                  style={{
                    backgroundColor: "green",
                    border: "none",
                  }}
                />
                Choose Files
              </Button>

              {/* ================================================ */}
              <Form.Group controlId="brand">
                <FloatingLabel
                  controlId="brandinput"
                  label="Brand"
                  className="mb-3"
                >
                  <Form.Control
                    size="lg"
                    placeholder="Enter brand"
                    type="text"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="category">
                <FloatingLabel
                  controlId="categoryinput"
                  label="Category"
                  className="mb-3"
                >
                  <Form.Select
                    size="lg"
                    placeholder="Enter category"
                    type="select"
                    value={category}
                    style={{ paddingBottom: "0px", fontSize: "16px" }}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="livestock">Livestock</option>
                    <option value="crops">Crops</option>
                    <option value="dairyProducts">Dairy Products</option>
                    <option value="packagedProducts">Packaged Products</option>
                    <option value="equipment">Equipment</option>
                    <option value="farmInput">Farm Input</option>
                    <option value="flowers">Flowers</option>
                    <option value="services">Services</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="countInStock">
                <FloatingLabel
                  controlId="countinstockinput"
                  label="CountInStock"
                  className="mb-3"
                >
                  <Form.Control
                    size="lg"
                    placeholder="Enter Count In Stock"
                    type="number"
                    min="0"
                    max="1000"
                    value={countInStock}
                    onChange={(e) => setCountInStock(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  size="lg"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <div className="d-flex">
                <Button type="submit" 
                    className="my-1 ms-auto" 
                    style={{ borderRadius: "8px", 
                    marginBottom: "16px", 
                    backgroundColor: 'green',
                    border: 'none' }}>
                  Create Product
                </Button>
              </div>
            </form>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default ProductCreate;
