import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
	Form,
	Button,
	Row,
	InputGroup,
	Col,
	Card,
	Image,
	FloatingLabel,
} from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import {
	sendVerficationEmail,
	getUserDetails,
	updateUserProfile,
	refreshLogin,
} from '../actions/userActions';
// import { USER_PROFILE_UPDATE_RESET } from '../constants/userConstants';
import Meta from '../components/Meta';
import axios from 'axios';
import '../styles/profile-page.css';

const ProfilePage = ({ history }) => {
	const inputFile = useRef(null);
	const [showSubmitButton, setShowSubmitButton] = useState(false); // sisable the submit button unless some user detail is changed by user
	const [typePassword, setTypePassword] = useState('password');
	const [typeConfirmPassword, setTypeConfirmPassword] = useState('password');

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [storeName, setStoreName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [country, setCountry] = useState('');
	const [state, setState] = useState('');
	const [region, setRegion] = useState('');
	const [address, setAddress] = useState('');
	const [description, setDescription] = useState('');
	const [avatar, setAvatar] = useState('');
	const [message, setMessage] = useState(null);

	const [uploading, setUploading] = useState(false);
	const [errorImageUpload, setErrorImageUpload] = useState('');
	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, user, error } = userDetails;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userProfileUpdate = useSelector((state) => state.userProfileUpdate);
	const { success } = userProfileUpdate;


	// check whether verification email has to be sent
	const userSendEmailVerfication = useSelector(
		(state) => state.userSendEmailVerfication
	);
	const { emailSent, hasError } = userSendEmailVerfication;

	// refresh access token for user details error
	useEffect(() => {
		if (error && userInfo) {
			const user = JSON.parse(localStorage.getItem('userInfo'));
			user && dispatch(refreshLogin(user.email));
		}
	}, [error, dispatch, userInfo]);


	// check if any of the input fields value is changed, only then show the submit button
	useEffect(() => {
		if (userInfo) {
			if (storeName && userInfo.storeName !== storeName) setShowSubmitButton(true);
			else if (email && userInfo.email !== email)
				setShowSubmitButton(true);
			else if (password || confirmPassword) setShowSubmitButton(true);
			else setShowSubmitButton(false);
		}
	}, [storeName, email, password, confirmPassword, userInfo]);


	useEffect(() => {
		if (!userInfo) {
			history.push('/login');
		} else {
			// if user is null, first fetch it and then set its details to the local state
			if (!user || !user.storeName || success) {
				userInfo
						&& dispatch(getUserDetails('profile'))
			} else {
			
				setFirstName(user.firstName)
				setLastName(user.lastName)
				setEmail(user.email)
				setStoreName(user.storeName)
				setPhoneNumber(user.phoneNumber)
				setAddress(user.address)
				setState(user.state)
				setRegion(user.region)
				setCountry(user.country)
				setDescription(user.description)
			}
		}
	}, [history, userInfo, user, dispatch, success]);

	const showHidePassword = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setTypePassword(typePassword === 'password' ? 'text' : 'password');
	};

	const showHideConfirmPassword = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setTypeConfirmPassword(
			typeConfirmPassword === 'password' ? 'text' : 'password'
		);
	};

	// handle file upload to aws bucket
	const handleImageUpload = async (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		setUploading(true);
		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			const { data } = await axios.post('/api/upload', formData, config);
			setAvatar(data);
			dispatch(
				updateUserProfile({
					id: user.id,
					avatar: data,
				})
			);
			setUploading(false);
		} catch (error) {
			setErrorImageUpload('Please choose a valid image');
			setUploading(false);
		}
	};

	// handle image overlay div's click to upload new file
	const handleImageClick = () => {
		inputFile.current.click();
	};

	// update user details
	const handleSubmit = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords do not match. Please retry.');
		} else {
			dispatch(
				updateUserProfile({
					id: user.id,
					
					email,
					avatar,
					password,
					confirmPassword,
				})
			);
		}
	};

	return (
		<Row className='mt-2'>
			<Meta title={`My Profile | ${storeName}`} />
			{userInfo && !userInfo.isConfirmed ? (
				<>
					{emailSent && (
						<Message variant='success' dismissible>
							A verification link has been sent your mail!
						</Message>
					)}
					{hasError && (
						<Message dismissible variant='danger'>
							{hasError}
						</Message>
					)}
					<Card style={{ margin: 'auto', maxWidth: '960px'}} className='mb-3' >
						<Card.Body className='ps-0 ' >
							<Card.Title style={{ fontWeight: 'bold' }}>
								Account Not Verified
							</Card.Title>
							<Card.Text>
								{`${userInfo.storeName}, `} your account is not yet
								verfied. Please{' '}
								<Button
									variant='link'
									className='p-0'
									style={{
										fontSize: '0.9em',
										margin: '0 0 0.1em 0',
										focus: 'none',
									}}
									onClick={() =>
										dispatch(
											sendVerficationEmail(userInfo.email)
										)
									}>
									click here
								</Button>{' '}
								to send a verfication email.
							</Card.Text>
						</Card.Body>
					</Card>
				</>
			) : null}
			<Col
				
				md={12}
				style={
					userInfo && !userInfo.isConfirmed
						? {		

								//UNCOMMENT TO CHANGE OPACITY WHEN EMAIL IS NOT VERIFIED
								// opacity: '0.5',
								// pointerEvents: 'none',
						  }
						: {
								opacity: '1',
								pointerEvents: '',
						  }
				}>
				<h2 className='text-center'>My Profile</h2>
				{message && (
					<Message dismissible variant='warning' duration={8} style={{maxWidth: '960px', margin: 'auto'}}>
						{message}
					</Message>
				)}
				{error && error !== 'Not authorised. Token failed' && (
					<Message dismissible variant='danger' duration={10} style={{maxWidth: '960px', margin: 'auto'}}>
						{error}
					</Message>
				)}
				{success && (
					<Message dismissible variant='success' duration={8} style={{maxWidth: '960px', margin: 'auto'}}>
						Profile Updated!
					</Message>
				)}
				{loading ? (
					<Loader />
				) : (
					<div style={{ display: 'flex', flexFlow: 'column nowrap', maxWidth: '960px', margin: 'auto'}}>
						{errorImageUpload && (
							<Message dismissible variant='danger' duration={10}>
								{errorImageUpload}
							</Message>
						)}
						{uploading ? (
							<Loader />
						) : (
							<div
								className='profile-page-image'
								style={{ alignSelf: 'center' }}>
								<Image
									src={avatar}
									alt={'avatar'}
									style={{
										height: '5em',
										width: '5em',
										marginBottom: '1em',
										border: '1px solid #ced4da',
										borderRadius: '50%',
										cursor: 'pointer',
									}}
								/>
								<div
									className='image-overlay'
									onClick={handleImageClick}>
									Click to upload image
								</div>
							</div>
						)}
						{/* for image upload */}
						<input
							type='file'
							accept="image/*"
							id='file'
							ref={inputFile}
							onChange={handleImageUpload}
							style={{ display: 'none' }}
						/>
						<Form onSubmit={handleSubmit}>
							
						<Form.Group controlId='firstName' className='mb-2'>
							<FloatingLabel
								controlId='firstName'
								label='First name'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter first name'
									type='text'
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId='name' className='mb-2'>
							<FloatingLabel
								controlId='lastName'
								label='Last name'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter last name'
									type='text'
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId='storeName' className='mb-2'>
							<FloatingLabel
								controlId='storeName'
								label='Store name'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter store name'
									type='text'
									value={storeName}
									onChange={(e) => setStoreName(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						
						<Form.Group controlId='email' className='my-2'>
							<FloatingLabel
								controlId='email'
								label='Email Address'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter email address'
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>

						<Form.Group controlId='phoneNumber' className='my-2'>
							<FloatingLabel
								controlId='phoneNumber'
								label='Phone number'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter phone number'
									type='phoneNumber'
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						
						<Form.Group>
							<InputGroup>
								<FloatingLabel
									controlId='password'
									label='Password'
									style={{
										display: 'flex',
										width: '100%',
									}}
									className='mb-3'>
									<Form.Control
										size='lg'
										type={typePassword}
										placeholder='Enter your password'
										value={password}
										style={{
											borderRight: 'none',
										}}
										onChange={(e) =>
											setPassword(e.target.value)
										}
									/>
									<div className='input-group-append'>
										<InputGroup.Text
											onClick={showHidePassword}
											style={{
												fontSize: '1rem',
												height: '100%',
												marginLeft: '-0.5em',
												background: 'transparent',
												borderLeft: 'none',
											}}>
											{typePassword === 'text' ? (
												<i className='far fa-eye-slash' />
											) : (
												<i className='far fa-eye' />
											)}
										</InputGroup.Text>
									</div>
								</FloatingLabel>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<InputGroup>
								<FloatingLabel
									controlId='confirmPassword'
									label='Confirm password'
									style={{ display: 'flex', width: '100%' }}
									className='mb-3'>
									<Form.Control
										size='lg'
										type={typeConfirmPassword}
										placeholder='Confirm your password'
										value={confirmPassword}
										style={{
											borderRight: 'none',
										}}
										onChange={(e) =>
											setConfirmPassword(e.target.value)
										}
									/>
									<div className='input-group-append'>
										<InputGroup.Text
											onClick={showHideConfirmPassword}
											style={{
												fontSize: '1rem',
												height: '100%',
												marginLeft: '-0.5em',
												background: 'transparent',
												borderLeft: 'none',
											}}>
											{typeConfirmPassword === 'text' ? (
												<i className='far fa-eye-slash' />
											) : (
												<i className='far fa-eye' />
											)}
										</InputGroup.Text>
									</div>
								</FloatingLabel>
							</InputGroup>
						</Form.Group>
						<Form.Group controlId='country' className='mb-2'>
							<FloatingLabel
								controlId='country'
								label='Country'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Select country'
									type='text'
									value={country}
									onChange={(e) => setCountry(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId='state' className='mb-2'>
							<FloatingLabel
								controlId='state'
								label='State'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Select state'
									type='text'
									value={state}
									onChange={(e) => setState(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId='region' className='mb-2'>
							<FloatingLabel
								controlId='region'
								label='Region'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Select region'
									type='text'
									value={region}
									onChange={(e) => setRegion(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId='address' className='mb-2'>
							<FloatingLabel
								controlId='address'
								label='Address'
								className='mb-3'>
								<Form.Control
									size='lg'
									placeholder='Enter address'
									type='text'
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</FloatingLabel>
						</Form.Group>
						
						<Form.Group className="mb-3" controlId="description">
    						<Form.Label>Description</Form.Label>
    						<Form.Control 
								as="textarea" 
								style={{fontSize: '16px'}}
								rows={5} 
								size='lg'
								placeholder='Description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
/>
								
  						</Form.Group>
							<div className='d-grid mb-3'>
								<Button
									type='submit'
									//disabled={!showSubmitButton}
									
									style={{
										padding: '0.5em 1em',
										borderRadius: "8px", 
                    					marginBottom: "16px", 
                    					backgroundColor: 'green',
                    					border: 'none'
									}}>
									Update Profile
								</Button>
							</div>
						</Form>
					</div>
				)}
			</Col>
			{console.log(showSubmitButton)}

		</Row>
	);
};

export default ProfilePage;
