import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { listProductDetails, updateProduct } from "../actions/productActions";
// import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
// import axios from 'axios';
import Loader from "../components/Loader";
import Message from "../components/Message";
import { refreshLogin, getUserDetails } from "../actions/userActions";

import FormContainer from "../components/FormContainer";

const ProductEditPage = ({ match, history }) => {
  // all variable for stroing product details
  const productId = match.params.id;
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  // const [image, setImage] = useState("");
  const [price, setPrice] = useState(0.0);
  const [countInStock, setCountInStock] = useState(0);

  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, product, error } = productDetails;

  const imageUrl = product.images && product.images.map((item, i) => item.url);

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
   // success: successUpdate,
    error: errorUpdate,
  } = productUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error: userLoginError } = userDetails;

  //==========================================================================

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldData) => [...oldData, reader.result]);
          setImages((oldData) => [...oldData, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  //==========================================================================

  const handleSubmit = (e) => {
    e.preventDefault();

    // required field checks

    if (images.length <= 0) {
      alert("Please upload an image");
      return;
    }

    const formData = new FormData();

    formData.set("name", name);
    formData.set("price", price);
    formData.set("brand", brand);
    formData.set("category", category);
    formData.set("countInStock", countInStock);
    formData.set("description", description);

    images.forEach((image) => {
      formData.append("images", image);
    });

    dispatch(updateProduct(productId, formData));
  };
  //==========================================================================

  // fetch user login details
  useEffect(() => {
    userInfo && dispatch(getUserDetails("profile"));
    //: dispatch(getUserDetails('profile'));
  }, [userInfo, dispatch]);
  // useEffect(() => {
  // 	userInfo
  // 		? userInfo.isSocialLogin
  // 			? dispatch(getUserDetails(userInfo.id))
  // 			: dispatch(getUserDetails('profile'))
  // 		: dispatch(getUserDetails('profile'));
  // }, [userInfo, dispatch]);

  // fetch new access tokens if user details fail, using the refresh token
  useEffect(() => {
    if (userLoginError && userInfo) {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      user && dispatch(refreshLogin(user.email));
    }
  }, [userLoginError, dispatch, userInfo]);

  useEffect(() => {
    dispatch(listProductDetails(productId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the product details in state
//   useEffect(() => {
//     if (successUpdate) {
//       dispatch({ type: PRODUCT_UPDATE_RESET });
//       history.push("/products");
//     } else {
//       if (!product || product._id !== productId) {
//         dispatch(listProductDetails(productId));
//       } else {
//         setName(product.name);
//         setPrice(product.price);
//         setImage(imageUrl);
//         setBrand(product.brand);
//         setCategory(product.category);
//         setDescription(product.description);
//         setCountInStock(product.countInStock);
//       }
//     }
//   }, [product, dispatch, productId, history, successUpdate, imageUrl]);

  //==========================================================================

  // // submit the product details
  // const handleSubmit = (e) => {
  // 	e.preventDefault();
  // 	dispatch(
  // 		updateProduct({
  // 			_id: productId,
  // 			name,
  // 			brand,
  // 			price,
  // 			category,
  // 			description,
  // 			countInStock,
  // 			image,
  // 		})
  // 	);
  // };

  // // for image input, use a ref
  // const inputFile = useRef(null);

  // // click the above ref, to handle the overlay div above the product image
  // const handleImageClick = () => {
  // 	inputFile.current.click();
  // };

  // // submit file to aws bucket, get the url
  // const handleFileUpload = async (e) => {
  // 	const file = e.target.files[0];
  // 	const formData = new FormData();
  // 	formData.append('image', file);
  // 	setUploading(true);
  // 	try {
  // 		const config = {
  // 			headers: {
  // 				'Content-Type': 'multipart/form-data',
  // 			},
  // 		};

  // 		const { data } = await axios.post('/api/upload', formData, config);
  // 		setImage(data);
  // 		setUploading(false);
  // 	} catch (error) {
  // 		setErrorImageUpload('Please choose a valid image');
  // 		setUploading(false);
  // 	}
  // };
  //==========================================================================

  useEffect(() => {
    if (product && product._id !== productId) {
      dispatch(listProductDetails(productId));
    } else {
      setName(product.name);
      setPrice(product.price);
      setBrand(product.brand);
      setCategory(product.category);
      setDescription(product.description);
      setCountInStock(product.countInStock);

      setOldImages(product.images);
    }
  }, [dispatch, productId, product]);

  return (
    <>
      {console.log(` Images: ${imageUrl}`)}
      <Link to="/products">
        <Button variant="outline-primary" className="mt-3">
          Go Back
        </Button>
      </Link>
      <FormContainer style={{ marginTop: "-2em" }}>
        <h1>Edit Product</h1>
        {loadingUpdate ? (
          <Loader />
        ) : errorUpdate ? (
          <Message dismissible variant="danger" duration={10}>
            {errorUpdate}
          </Message>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                {error && (
                  <Message dismissible variant="danger" duration={10}>
                    {error}
                  </Message>
                )}
                <Form.Group controlId="name">
                  <FloatingLabel
                    controlId="nameinput"
                    label="Product name"
                    className="mb-3"
                  >
                    <Form.Control
                      size="lg"
                      placeholder="Enter Name"
                      type="text"
                    value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group controlId="price">
                  <FloatingLabel
                    controlId="priceinput"
                    label="Price"
                    className="mb-3"
                  >
                    <Form.Control
                      size="lg"
                      placeholder="Enter price"
                      type="number"
                      value={price}
                      min="0"
                      step="0.1"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
			

                {/* ================================================ */}

                {/* <h6> Product Image</h6>
                    <div style={{ width: '120px', }}>
                        {imagesPreview.map((image, i) => (
                            <img draggable="false" src={imageUrl.toString()} style={{ width: '120px', borderRadius: '8px' , marginBottom: '16px'}} alt="Product" key={i} />
                        ))}
                    </div>
                    <Button style={{borderRadius: '8px', marginBottom: '16px'}} >
                        <input
                            type="file"
                            name="images"
                            accept="image/*"
                            multiple
                            onChange={handleProductImageChange}
                            className="hidden"
                        />
                        Choose Files
                    </Button> */}

                {/* ================================================ */}

                {/* ================================================ */}

                <h6>Product Images</h6>
                <div style={{ width: "120px" }}>
                  {oldImages &&
                    oldImages.map((image, i) => (
                      <img
                        draggable="false"
                        src={image.url}
                        style={{
                          width: "120px",
                          borderRadius: "8px",
                          marginBottom: "16px",
                        }}
                        alt="Product"
                        key={i}
                      />
                    ))}
                  {imagesPreview.map((image, i) => (
                    <img
                      draggable="false"
                      src={image}
                      alt="Product"
                      key={i}
                      style={{
						width: "120px",
						borderRadius: "8px",
						marginBottom: "16px",
					  }}
                    />
                  ))}
                </div>
				<Button style={{borderRadius: '8px', marginBottom: '16px'}} >
                  <input
                    type="file"
                    name="images"
                    accept="image/*"
                    multiple
                    onChange={handleProductImageChange}
                    className="hidden"
                  />
                  Choose Files
                </Button>

                {/* ================================================ */}
                <Form.Group controlId="brand">
                  <FloatingLabel
                    controlId="brandinput"
                    label="Brand"
                    className="mb-3"
                  >
                    <Form.Control
                      size="lg"
                      placeholder="Enter brand"
                      type="text"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="category">
                  <FloatingLabel
                    controlId="categoryinput"
                    label="Category"
                    className="mb-3"
                  >
                    <Form.Select
                      size="lg"
                      placeholder="Enter category"
                      type="select"
                      value={category}
                      style={{ paddingBottom: "0px", fontSize: "16px" }}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="livestock">Livestock</option>
                      <option value="crops">Crops</option>
                      <option value="dairyProducts">Dairy Products</option>
                      <option value="packagedProducts">
                        Packaged Products
                      </option>
                      <option value="equipment">Equipment</option>
                      <option value="farmInput">Farm Input</option>
                      <option value="flowers">Flowers</option>
                      <option value="services">Services</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="countInStock">
                  <FloatingLabel
                    controlId="countinstockinput"
                    label="CountInStock"
                    className="mb-3"
                  >
                    <Form.Control
                      size="lg"
                      placeholder="Enter Count In Stock"
                      type="number"
                      min="0"
                      max="10000"
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={8}
                    size="lg"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex">
                  <Button type="submit" className="my-1 ms-auto">
                    Update Product
                  </Button>
                </div>
              </form>
            )}
          </>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditPage;
