export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAILURE = 'ORDER_PAY_FAILURE';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAILURE = 'ORDER_DELIVER_FAILURE';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';

export const ORDER_USER_LIST_REQUEST = 'ORDER_USER_LIST_REQUEST';
export const ORDER_USER_LIST_SUCCESS = 'ORDER_USER_LIST_SUCCESS';
export const ORDER_USER_LIST_FAILURE = 'ORDER_USER_LIST_FAILURE';
export const ORDER_USER_LIST_RESET = 'ORDER_USER_LIST_RESET';

export const ORDER_ALL_LIST_REQUEST = 'ORDER_ALL_LIST_REQUEST';
export const ORDER_ALL_LIST_SUCCESS = 'ORDER_ALL_LIST_SUCCESS';
export const ORDER_ALL_LIST_FAILURE = 'ORDER_ALL_LIST_FAILURE';

export const ORDER_COUNT_REQUEST = 'ORDER_COUNT_REQUEST';
export const ORDER_COUNT_SUCCESS = 'ORDER_COUNT_SUCCESS';
export const ORDER_COUNT_FAILURE = 'ORDER_COUNT_FAILURE';
