import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Meta from "../components/Meta";
import { refreshLogin, getUserDetails } from "../actions/userActions";
// import { countMyOrders } from "../actions/orderActions";
// import { countProducts } from "../actions/productActions";
// import Message from "../components/Message";
// import Loader from "../components/Loader";

const HomePage = ({ history }) => {
  const [promptVerification, setPromptVerification] = useState(false); // prompt user to verify email if not yet confirmed
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error: userInfoError } = userDetails;

  //   const countOrders = useSelector((state) => state.countOrders);
  //   const { loading: loadingOrderCount, error: errorOrderCount,  orderCount: count } = countOrders
  //   const { orderCount: numberOfOrders} = count

  //   const productCount = useSelector((state) => state.productCount);
  //   const { loading: loadingProductCount, error: errorProductCount, numProducts } = productCount;
  //   const { numProducts: numberOfProducts} = numProducts

  console.log(promptVerification);

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  //fetch the user details
  useEffect(() => {
    userInfo
      ? userInfo.isSocialLogin
        ? dispatch(getUserDetails(userInfo.id))
        : dispatch(getUserDetails("profile"))
      : dispatch(getUserDetails("profile"));
  }, [userInfo, dispatch]);

  //refresh token to get new access token if error in user details
  useEffect(() => {
    if (userInfoError && userInfo && !userInfo.isSocialLogin) {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      dispatch(refreshLogin(user?.email));
    }
  }, [userInfoError, dispatch, userInfo]);

  //   useEffect(() => {
  // 	  if(userInfo && userInfoError && !userInfo.isSocialLogin) {
  // 		  dispatch(countProducts(userInfo.id))
  // 	  }
  //   }, [userInfo, dispatch, userInfoError])

  //   useEffect(() => {
  // 	  if(userInfo && userInfoError && !userInfo.isSocialLogin) {
  // 		  dispatch(countMyOrders(userInfo.storeName))
  // 	  }
  //   }, [userInfo, userInfoError, dispatch])

  // check if user needs to be promted about email verification on page load
  useEffect(() => {
    setPromptVerification(
      localStorage.getItem("promptEmailVerfication") === "true" ? true : false
    );
  }, []);

  return (
    <>
      <Meta />
      <h1
        style={{
          border: "0.5px solid #9ab49a",
          marginBottom: "16px",
          borderRadius: "16px",
          paddingLeft: "12px",
          backgroundColor: "#e3ffe3",
          color: "grey",
        }}
      >
        Dashboard
      </h1>
      <Row>
        <Col md={3}>
          <Card
            style={{
              width: "16rem",
              borderRadius: "12px",
              border: "0.2px solid green",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  textAlign: "center",
                  border: "1px solid green",
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                My Orders
              </Card.Title>
              <Card.Text
                as="h1"
                style={{ textAlign: "center", margin: "24px" }}
              >
                {/* <p style={{ color: "gray", fontSize: "56px" }}>
                  {loadingOrderCount ? (
                    <Loader />
                  ) : errorOrderCount ? (
                    <Message>{errorOrderCount}</Message>
                  ) : numberOfOrders <= 0 ? (
                    0
                  ) : (
                    numberOfOrders
                  )}
                </p> */}
              </Card.Text>
              <Link
                to="/orders"
                style={{ margin: "auto", justifyContent: "center" }}
              >
                <div
                  className="go-back"
                  style={{
                    margin: "auto",
                    display: "block",
                    maxWidth: "140px",
                    textAlign: "center",
                  }}
                >
                  View Orders
                </div>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card
            style={{
              width: "16rem",
              borderRadius: "12px",
              border: "0.2px solid green",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{
                  textAlign: "center",
                  border: "1px solid green",
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "green",
                  color: "white",
                }}
              >
                My Products
              </Card.Title>
              <Card.Text
                as="h1"
                style={{ textAlign: "center", margin: "24px" }}
              >
                {/* <p style={{ color: "gray", fontSize: "56px" }}>
                  {loadingProductCount ? (
                    <Loader />
                  ) : errorProductCount ? (
                    <Message>{errorProductCount}</Message>
                  ) : numberOfProducts <= 0 ? (
                    0
                  ) : (
                    numberOfProducts
                  )}
                </p> */}
              </Card.Text>
              <Link
                to="/products"
                style={{ margin: "auto", justifyContent: "center" }}
              >
                <div
                  className="go-back"
                  style={{
                    margin: "auto",
                    display: "block",
                    maxWidth: "140px",
                    textAlign: "center",
                  }}
                >
                  View Products
                </div>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
